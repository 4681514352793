import { BsBuilding } from "react-icons/bs";
import { HiOutlineChat } from "react-icons/hi";
import Layout from "../components/layout/Layout";
import NavBar from "../components/layout/NavBar";

function Main() {
  return (
    <Layout>
      <NavBar />
      <div className="w-full flex justify-center">
        <img
          src={process.env.PUBLIC_URL + "/assets/img/main.png"}
          alt="main_img"
          className="rounded-md"
        />
      </div>
      <Body />
      <Footer />
    </Layout>
  );
}

export default Main;

function Body() {
  return (
    <>
      <div className="flex flex-col md:flex-row w-full mt-3">
        <div className="flex flex-1">
          <div className="flex items-start mt-[10px] px-2">
            <HiOutlineChat size={20} />
          </div>
          <div className="flex flex-col w-full">
            <div className="w-full flex items-center py-2">상담 & 문의</div>
            <div className="flex flex-col border-y-2 border-black w-full py-2 gap-1">
              <div className="font-semibold">Tel: 041. 545. 4229</div>
              <div className="font-semibold">카카오톡 채널: '청경 학원'</div>
              <div className="font-semibold">
                E-mail: bluewhale1029@gmail.com
              </div>
            </div>
            {/* <div className="flex flex-col py-2 gap-1">
              <div className="font-semibold">(카카오톡 채널) '청경 Helper'</div>
              <div>오후 1:00 ~ 오전 12:00</div>
              <div className="font-semibold">
                (E-mail) bluewhale1029@gmail.com
              </div>
            </div> */}
          </div>
        </div>
        <div className="flex flex-1">
          <div className="flex items-start mt-[12px] px-2">
            <BsBuilding size={20} />
          </div>
          <div className="flex flex-col w-full">
            <div className="w-full flex items-center py-2">주소</div>
            <div className="flex flex-col border-t-2 border-black w-full py-2 gap-1">
              <div className="font-semibold">청경학원</div>
              <div>충청남도 아산시 번영로 234번길 2 메디포스 9층</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function Footer() {
  return <></>;
}
